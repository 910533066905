<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <form @submit.prevent="assign" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Assign to client(s)</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <div v-if="!isLoading">
            <div v-if="isChoosingClients">
              <div class="border-bottom pb-3">
                <IconButton
                  @click="isChoosingClients = false"
                  :icon="'chevron-left'"
                  :text="'Back'"
                  class="btn btn-primary"
                ></IconButton>
              </div>

              <!-- CLIENT SELECTOR -->
              <div v-if="clients.length" class="list-group list-group-flush">
                <div
                  v-for="(client, clientIndex) in clients"
                  :key="`key-${clientIndex}`"
                  class="list-group-item"
                >
                  <div class="form-check">
                    <input
                      v-model="selectedClients"
                      :id="`cb-${clientIndex}`"
                      :value="client"
                      class="form-check-input"
                      type="checkbox"
                    >
                    <label
                      :for="`cb-${clientIndex}`"
                      class="form-check-label"
                    >{{ client | fullname }}</label>
                  </div>
                </div>
              </div>
              <div v-else class="mt-3">No clients found</div>
            </div>

            <div v-else>
              <!-- PROGRAM -->
              <div>
                <div class="fw-bold">Program</div>
                <div>{{ program.name }}</div>
              </div>

              <!-- CLIENTS -->
              <div class="mt-3">
                <div class="fw-bold">Clients</div>
                <div v-if="selectedClientNames">
                  <div>{{ selectedClientNames }}</div>
                  <a
                    @click="isChoosingClients = true"
                    class="text-decoration-underline fst-italic"
                    role="button"
                  >Change</a>
                </div>
                <div v-else class="text-muted fst-italic">
                  None selected.
                  <a
                    @click="isChoosingClients = true"
                    class="text-decoration-underline"
                    role="button"
                  >Choose clients</a>
                </div>
              </div>

              <!-- START DATE -->
              <div class="mt-3">
                <div class="fw-bold">Start date</div>
                <div>The program will kick off on {{ selectedDate.format('LL') }}</div>
                <DatePickerField
                  @select="selectedDateChange"
                  :date="selectedDate.format(dateFormat)"
                  class="mt-1"
                ></DatePickerField>
              </div>
            </div>
          </div>

          <LoadingMessage v-if="isLoading" :message="'Fetching clients...'"></LoadingMessage>

          <ErrorMessage v-if="error" :error="error" class="m-0 mt-3"></ErrorMessage>

          <div v-if="isSuccess" class="alert alert-success m-0 mt-3">Done!</div>
        </div>
        <div v-if="!isChoosingClients" class="modal-footer">
          <SubmitButton
            :text="'Assign'"
            :textBusy="'Please wait...'"
            :isLoading="isSubmitting"
            :disabled="isLoading || isSubmitting || isSubmitDisabled"
            class="btn btn-primary"
          ></SubmitButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { httpGet, httpPost } from '@/core/http';

dayjs.extend(customParseFormat);

export default {
  name: 'ProgramAssignClientsModal',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    DatePickerField: () => import('@/components/field/DatePickerField'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isSubmitDisabled() {
      const { selectedClients, selectedDate } = this;
      return !selectedClients.length || !selectedDate.isValid();
    },
    selectedClientNames() {
      const { selectedClients } = this;
      if (selectedClients.length) {
        const names = selectedClients.map((client) => {
          const x = this.$options.filters.fullname(client);
          return x;
        }).join(', ');
        return names;
      }
      return null;
    },
  },
  methods: {
    close() {
      if (this.isLoading || this.isSubmitting) return;
      this.$emit('close');
    },
    async fetchClients() {
      this.isLoading = true;
      this.clients = [];
      this.selectedClients = [];
      try {
        const res = await httpGet('/coaching/clients');
        this.clients = res.data;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    async assign() {
      this.isSubmitting = true;
      this.isSuccess = false;
      this.error = null;
      try {
        const { program: { programId }, selectedClients, selectedDate } = this;
        const userIds = selectedClients.map((client) => client.userId);
        const date = selectedDate.format('YYYY-MM-DD');
        await httpPost(`/program/${programId}/assignmultiple`, { userIds, date });
        this.isSuccess = true;
      } catch (err) {
        this.error = err;
      } finally {
        this.selectedClients = [];
        this.isSubmitting = false;
      }
    },
    selectedDateChange(dateString) {
      const today = dayjs().hour(0).minute(0).second(0);
      const d = dayjs(dateString, this.dateFormat).hour(1);
      if (d.isBefore(today)) {
        this.error = new Error('Cannot select past date');
      } else {
        this.error = null;
        this.selectedDate = d;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      isSuccess: false,
      isChoosingClients: false,
      error: null,
      clients: [],
      selectedClients: [],
      selectedDate: dayjs().hour(0).minute(0).second(0),
      dateFormat: 'DD-MM-YYYY',
    };
  },
  mounted() {
    this.fetchClients();
  },
};
</script>
